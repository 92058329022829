/*--- Sidebar humberger icon ---*/
$( document ).ready(function() {
    if (iniframe()) {
        $(".sidebar").remove();
        $(".header").css("margin-left", "0px");
        $(".hamburger-container").remove();
    }
    
   $(".hamburger").on('click', function() {
        $(this).toggleClass("is-active");
        if($(this).hasClass("is-active")){
          $("body").addClass("sidebar-hide");

          // hide large sidebar details when hambuger is clicked   
          $(".nano-content h1").css("display", "none");
          $(".logo").css("display", "none");
          $(".sidebar-copy").css("display", "none");
        }else{
          $("body").removeClass("sidebar-hide");
          $(".nano-content h1").css("display", "block");
          $(".logo").css("display", "block");
          $(".sidebar-copy").css("display", "block");
        }        
    });

    /*---  item active ---*/
    $('.header li, .sidebar li').on('click', function() {
        $(".header li.active, .sidebar li.active").removeClass("active");
        $(this).addClass('active');
    });

    $(".header li").on("click", function(event) {
        event.stopPropagation();
    });

    $(document).on("click", function() {
        $(".header li").removeClass("active");
    });

    $(".nano-content").find("li").click(function(){
        if ($(this).hasClass('open')){
            $(this).removeClass('open')
        }else{
            $(".nano-content").find('li.open').removeClass('open')
            $(this).addClass('open')
        }
    })
});

function iniframe() {
    if (window.location !== window.parent.location) { 
        console.log("in iframe");
        return true;
    } else {
        console.log("not in iframe");
        return false;
    }
}